import $ from "jquery";
window.$ = window.jQuery = $;
import barba from "@barba/core";
// import "bootstrap";

import { jsPDF } from "jspdf";

import Scrollbar from "smooth-scrollbar";
// import "slick-carousel";

// import svgPanZoom from "svg-pan-zoom";
// import Hammer from "hammerjs";

import {
  TimelineMax,
  TweenMax,
  TweenLite,
  Linear,
  TextPlugin,
  ThrowPropsPlugin,
  EasePack,
  CSSPlugin,
  ScrollToPlugin,
  Power1,
  Power2,
  Power4,
  Expo,
  gsap,
} from "gsap";

import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

// function unwrap(wrapper) {
//   var docFrag = document.createDocumentFragment();
//   while (wrapper.firstChild) {
//     var child = wrapper.removeChild(wrapper.firstChild);
//     docFrag.appendChild(child);
//   }
//   wrapper.parentNode.replaceChild(docFrag, wrapper);
// }

var screen_cover = document.querySelectorAll(".screen_cover");

function loaded() {
  if (document.querySelector(".page_nav_list") != null) {
    var page_nav_list = document.querySelector(".page_nav_list");
    var item_active_hint = page_nav_list.querySelector(".item_active_hint");
    var list_item = page_nav_list.querySelectorAll(".list_item");

    list_item.forEach((ele) => {
      var item_active_hint_position =
        ele.offsetLeft +
        ele.offsetWidth * 0.5 -
        item_active_hint.offsetWidth * 0.5;
      var item_link = ele.querySelector(".item_link");

      ele.addEventListener("touchstart", start);
      ele.addEventListener("touchend", end);
      ele.addEventListener("touchmove", start);

      ele.addEventListener("mouseenter", start);
      ele.addEventListener("mouseleave", end);
      ele.addEventListener("mousedown", click);
      // ele.addEventListener("click", click);

      function start(event) {
        if (
          event.type === "touchstart" ||
          event.type === "touchmove" ||
          event.type === "mouseenter"
        ) {
          ele.classList.add("active");
          item_active_hint.classList.add("active");
          item_active_hint.style.left = item_active_hint_position + "px";
        }
      }
      function end(event) {
        if (event.type === "touchend" || event.type === "mouseleave") {
          ele.classList.remove("active");
          item_active_hint.style.left = item_active_hint.dataset.current + "px";
        }
      }
      function click(event) {
        item_active_hint.style.left = item_active_hint_position + "px";
      }

      if (item_link.href == location.href) {
        item_active_hint.classList.add("active");
        item_active_hint.style.left = item_active_hint_position + "px";
        item_active_hint.dataset.current = item_active_hint_position;
      } else {
        // item_active_hint.classList.toggle("active");
      }
    });
  }


  if (document.querySelector(".get_certificate_btn") != null) {
    var doc = new jsPDF("p", "mm", "a4");
    var certificate = document.querySelectorAll(".get_certificate_btn");
    var width = doc.internal.pageSize.getWidth();
    var height = doc.internal.pageSize.getHeight();

    certificate.forEach((ele) => {
      var template = ele.dataset.template;
      var filename = ele.dataset.filename;
      var str_name = '茲恭喜　' + ele.dataset.name + '　學員';
      ele.addEventListener('click', ()=>{
        ele.classList.add('loading');
        ele.textContent = '證書製作中，請稍候';
        setTimeout(()=>{
          doc.addImage(template, "JPEG", 0, 0, width, height);
          doc.setFontSize(22);
          doc.addFont('../app/themes/summerschool/src/fonts/SourceHanSans-Medium.ttf', 'SourceHanSans-Medium', 'normal');
          doc.setFont('SourceHanSans-Medium');
          // doc.text(name, 90, 137, 'center');
          doc.text(str_name, width / 2, 137, {align: 'center'});
          doc.save("2021_summerschool_" + filename + ".pdf");
          ele.classList.remove('loading');
          ele.textContent = '恭喜你完成冒險！';
        }, 1000);
      });
    });

  }
}

function footer_visual(){
    var brand_footer_height = document.querySelector(".brand_footer")
      .offsetHeight;

    gsap.to(".inner_footer .sub_a_0 .shape, .inner_footer .sub_a_1 .shape", 2, {
      scrollTrigger: {
        trigger: ".footer",
        start: "5% bottom",
        end: "bottom top",
        scrub: true,
        // markers: true,
      },
      y: -brand_footer_height * 0.5,
      opacity: 1,
      // delay: 0.7,
      ease: Power2.easeOut,
    });
    gsap.to(
      ".inner_footer .sub_a_3 .shape, .inner_footer .sub_b_2 .shape, .inner_footer .sub_a_4 .shape",
      2,
      {
        scrollTrigger: {
          trigger: ".footer",
          start: "top bottom",
          end: "bottom top",
          scrub: true,
          // markers: true,
        },
        y: -brand_footer_height * 0.5,
        opacity: 1,
        // delay: 0.8,
        ease: Power2.easeOut,
      }
    );
    gsap.to(
      ".inner_footer .sub_b_1 .shape, .inner_footer .sub_b_3 .shape, .inner_footer .sub_a_2 .shape",
      2,
      {
        scrollTrigger: {
          trigger: ".footer",
          start: "top bottom",
          end: "-5% top",
          scrub: true,
          // markers: true,
        },
        y: -brand_footer_height * 0.2,
        opacity: 1,
        // delay: 0.9,
        ease: Power2.easeOut,
      }
    );
    gsap.to(
      ".inner_footer .nav_course .shape, .inner_footer .nav_map .shape",
      2,
      {
        scrollTrigger: {
          trigger: ".footer",
          start: "top bottom",
          end: "bottom top",
          scrub: true,
          // markers: true,
        },
        y: -brand_footer_height * 0.5,
        opacity: 1,
        // delay: 1,
        ease: Power2.easeOut,
      }
    );

    gsap.to(".inner_footer .main_a .shape", 5, {
      y: 0,
      delay: 0.2,
      ease: Expo.easeOut,
    });
    gsap.to(".inner_footer .main_b .shape", 5, {
      y: 0,
      delay: 0.4,
      ease: Expo.easeOut,
    });
    gsap.to(".inner_footer .main_c .shape", 5, {
      y: 0,
      delay: 0.6,
      ease: Expo.easeOut,
    });
    gsap.to(".page_nav_box .page_nav_list", 2, {
      scrollTrigger: {
        trigger: ".footer",
        start: "top bottom",
        end: "50% top",
        scrub: true,
        // markers: true,
      },
      // y: '0%',
      opacity: 0,
      // delay: 0.8,
      ease: Power2.easeOut,
    });
}

function scrolling() {
  const all_img = document.querySelectorAll("img[data-src]");
  all_img.forEach((element) => {
    if (element.getBoundingClientRect().y - window.innerHeight * 1 < 0) {
      element.setAttribute("src", element.dataset.src);
    }
  });

  const section = document.querySelectorAll("section, .fadein_item");
  section.forEach((element) => {
    if (element.getBoundingClientRect().y - window.innerHeight * 0.9 < 0) {
      element.classList.add("enter");
    } else {
      // element.classList.remove("enter");
    }
  });

  // window.addEventListener("scroll", function() {
  //   scrolling();
  // });
  // console.log('1');
}


window.addEventListener("scroll", function() {
  scrolling();
});

function cursor() {}

document.addEventListener("DOMContentLoaded", function() {
  loaded();
  scrolling();

  if (document.querySelector(".canvas") != null) {
    init();
    animate();
  }

  if (document.querySelector(".woocommerce-notice") != null) {
    document.querySelector(".woocommerce-notice").addEventListener('click', ()=>{
      document.querySelector(".woocommerce-notice-wrap").classList.add('fade_hidden');
    });
  }

  if (document.querySelector(".woocommerce") != null) {
    document.querySelector(".page_nav_box").classList.add("active");
  }

  if (screen_cover != null) {
    screen_cover.forEach((item)=>{
      item.style.height = window.innerHeight + "px";
    });
  }

  localStorage.setItem("hero_video_set", "0");
});

window.addEventListener("resize", function() {
  if (screen_cover != null) {
    screen_cover.forEach((item) => {
      item.style.height = window.innerHeight + "px";
    });
  }
});

const loadScript = (name, url, options = {}) => {
  const existingScript = document.getElementById(name);
  if (!existingScript) {
    const script = document.createElement('script');
    script.src = url;
    Object.keys(options).map((key) => script[key] = options[key]);
    script.id = name;
    document.body.appendChild(script);
  }
}

const removeScript = (name) => {
  const existingScript = document.getElementById(name);
  if (existingScript) {
    existingScript.parentNode.removeChild(existingScript)
  }
}


// var hero_video_set = localStorage.getItem("hero_video_set");


barba.init({
  // debug: true,
  prevent: ({ el }) => el.classList && el.classList.contains("prevent"),
  schema: {
    prefix: "data-based",
    // wrapper: "wrap",
  },
  views: [
    {
      namespace: "home",
      once(data) {
      },
      beforeEnter({ next }) {
        document.querySelector(".page_nav_box").classList.remove("active");
        document.querySelector(".inner_footer").classList.remove("active");
        if (localStorage.getItem("hero_video_set") == 1) {
          document.querySelector(".nav_header").classList.remove("fade_hidden");
          document.querySelector(".hero_video").classList.add("hidden_s");
        } else {
          document.querySelector(".nav_header").classList.add("fade_hidden");
        }

      },
      enter() {},
      afterEnter(data, current) {
        
        document.querySelectorAll(".screen_cover").forEach((item) => {
          item.style.height = window.innerHeight + "px";
        });
        // console.log(localStorage.getItem("hero_video_set"));
        // localStorage.setItem("hero_video_set", "1");

        if (localStorage.getItem("hero_video_set") == 1) {
          document.querySelector(".nav_header").classList.remove("fade_hidden");
          document.querySelector(".hero_video").classList.add("hidden_s");
          keyvisual_enter();
          notification_item();
          nav_pointer();
        } else {
          localStorage.setItem("hero_video_set", "1");
          const nav_header = document.querySelector(".nav_header");
          const hero_video = document.querySelector(".hero_video");
          const video_box = hero_video.querySelector(".video_box");
          const cover_video = hero_video.querySelector(".cover_video");
          const main_video = hero_video.querySelector(".main_video");
          const skip_video = hero_video.querySelector(".skip_video");
          const video_progress = hero_video.querySelector(".video_progress");
          video_box.addEventListener("click", handlePlayButton, false);

          async function playVideo() {
            try {
              await main_video.play();
              hero_video.classList.toggle("playing");
              cover_video.classList.add("fade_hidden");
              var i = setInterval(function() {
                if (main_video.readyState > 0) {
                  // modal_video minutes = parseInt(video.duration / 60, 10);
                  // var seconds = video.duration % 60;
                  var duration = main_video.duration;
                  var currentTime = main_video.currentTime;
                  var currentPercentage = Math.floor(
                    (currentTime / duration) * 100
                  ); // console.log(currentPercentage);

                  video_progress.style.width = currentPercentage + "%";

                  if (parseInt(duration) - parseInt(currentTime) == 0) {
                    clearInterval(i);
                    hero_video.classList.remove("playing");
                    hero_video.classList.add("fade_hidden");
                    keyvisual_enter();
                    setTimeout(() => {
                      nav_header.classList.remove("fade_hidden");
                      notification_item();
                      nav_pointer();
                    }, 300);
                  }
                }
              }, 100);
            } catch (err) {}
          }

          function handlePlayButton() {
            if (main_video.paused) {
              playVideo();
            } else {
              main_video.pause();
              hero_video.classList.remove("playing");
            }
          }

          skip_video.addEventListener("click", function() {
            playVideo();
            hero_video.classList.remove("playing");
            hero_video.classList.add("fade_hidden");
            keyvisual_enter();
            setTimeout(() => {
              nav_header.classList.remove("fade_hidden");
              notification_item();
              nav_pointer();
            }, 300);
          });
        }

        function notification_item() {
          if (document.querySelector(".notification_item") != null) {
            var notification_item = document.querySelectorAll(
              ".notification_item"
            );
            notification_item.forEach((item, index) => {
              var start_time = item.dataset.start * 1000;

              notification_item.forEach(() => {
                notification_item[index].style.top =
                  item.offsetHeight * index + "px";
              });

              item.addEventListener("mouseenter", () => {
                item.classList.add("hover");
              });
              item.addEventListener("mouseleave", () => {
                item.classList.remove("hover");
              });
              setTimeout(() => {
                item.classList.add("active");
                // if (index > 0) {
                //   notification_item.forEach((item2, index2) => {
                //     notification_item[index].style.top =
                //       item.offsetHeight * index + "px";
                //   });
                // }
              }, start_time);
            });

            notification_item.forEach((item, index) => {
              item.addEventListener("click", () => {
                item.classList.remove("active");
              });
            });
          }
        }

        function nav_pointer() {
          var nav_pointer = document.querySelectorAll(".nav_pointer");

          nav_pointer.forEach((ele) => {
            var hint = document.querySelector("." + ele.id + "_hint");
            ele.addEventListener("mouseenter", () => {
              hint.classList.add("active");
            });
            ele.addEventListener("mouseleave", () => {
              hint.classList.remove("active");
            });

            ele.addEventListener("touchstart", () => {
              hint.classList.add("active");
            });
            ele.addEventListener("touchend", () => {
              hint.classList.remove("active");
            });
          });
        }

        function keyvisual_enter() {
          // init();
          // animate();
          gsap.to(
            ".home_container .sub_a_0 .shape, .home_container .sub_a_2 .shape, .home_container .sub_a_3 .shape",
            2,
            {
              y: 0,
              opacity: 1,
              delay: 0.7,
              ease: Power2.easeOut,
            }
          );
          gsap.to(
            ".home_container .sub_a_1 .shape, .home_container .sub_b_2 .shape, .home_container .sub_a_4 .shape",
            2,
            {
              y: 0,
              opacity: 1,
              delay: 0.8,
              ease: Power2.easeOut,
            }
          );
          gsap.to(
            ".home_container .sub_b_1 .shape, .home_container .sub_b_3 .shape",
            2,
            {
              y: 0,
              opacity: 1,
              delay: 0.9,
              ease: Power2.easeOut,
            }
          );
          gsap.to(".home_container .nav_about .shape", 1.5, {
            y: 0,
            opacity: 1,
            delay: 1.3,
            ease: Expo.easeOut,
          });
          gsap.to(".home_container .nav_map .shape", 1.5, {
            y: 0,
            opacity: 1,
            delay: 1.5,
            ease: Expo.easeOut,
          });
          gsap.to(".home_container .nav_course .shape", 1.5, {
            y: 0,
            opacity: 1,
            delay: 1.7,
            ease: Expo.easeOut,
          });

          gsap.to(".home_container .main_a .shape", 5, {
            y: 0,
            delay: 0.2,
            ease: Expo.easeOut,
          });
          gsap.to(".home_container .main_b .shape", 5, {
            y: 0,
            delay: 0.4,
            ease: Expo.easeOut,
          });
          gsap.to(".home_container .main_c .shape", 5, {
            y: 0,
            delay: 0.6,
            ease: Expo.easeOut,
          });
        }

        var keyvisual = document.querySelector(".home_container .keyvisual");

        keyvisual.addEventListener("touchstart", moving);
        keyvisual.addEventListener("touchmove", moving);
        keyvisual.addEventListener("mousemove", moving);

        function moving(event) {
          if (event.type === "touchmove") {
            var point_x = event.touches[0].clientX;
            var point_y = event.touches[0].clientY;
          }
          if (event.type === "mousemove") {
            var point_x = event.clientX;
            var point_y = event.clientY;
          }

          gsap.to(".home_container .layer_0_1", 1, {
            // rotationY: shadowX,
            // rotationX: shadowY * -2,
            transformPerspective: 4000,
            // x: point_x * 0.005,
            y: point_y * 0.005,
            z: point_x * 0.1,
            ease: Expo.easeOut,
          });
          gsap.to(".home_container .layer_0_2", 1, {
            // rotationY: shadowX,
            // rotationX: shadowY * -2,
            transformPerspective: 4000,
            x: point_x * 0.005,
            y: point_y * 0.005,
            z: point_x * 0.1,
            ease: Expo.easeOut,
          });
          gsap.to(".home_container .layer_1", 1, {
            // rotationY: shadowX,
            // rotationX: shadowY * -2,
            transformPerspective: 2500,
            x: point_x * 0.02,
            y: point_y * 0.04,
            z: point_x * 0.08,
            ease: Expo.easeOut,
          });
          gsap.to(".home_container .layer_2", 1, {
            // rotationY: shadowX,
            // rotationX: shadowY * -2,
            transformPerspective: 1000,
            x: point_x * 0.04,
            y: point_y * 0.02,
            z: point_x * 0.04,
            ease: Expo.easeOut,
          });
          gsap.to(".home_container .layer_3", 1, {
            // rotationY: shadowX,
            // rotationX: shadowY * -2,
            transformPerspective: 1000,
            x: point_x * 0.01,
            y: point_y * 0.02,
            z: point_x * 0.01,
            ease: Expo.easeOut,
          });
          gsap.to(".home_container .layer_4", 1, {
            // rotationY: shadowX,
            // rotationX: shadowY * -2,
            transformPerspective: 1000,
            x: point_x * 0.01,
            y: point_y * 0.04,
            z: point_x * 0.01,
            ease: Expo.easeOut,
          });
          gsap.to(".home_container .layer_5", 1, {
            // rotationY: shadowX,
            // rotationX: shadowY * -2,
            transformPerspective: 1000,
            x: point_x * 0.02,
            y: point_y * 0.04,
            // z: point_x * 0.01,
            ease: Expo.easeOut,
          });
          gsap.to(".home_container .layer_6", 1, {
            // rotationY: shadowX,
            // rotationX: shadowY * -2,
            transformPerspective: 1000,
            x: point_x * -0.02,
            y: point_y * 0.03,
            z: point_x * 0.01,
            ease: Expo.easeOut,
          });
        }
        // document.querySelector(".page_nav_box").classList.remove("active");
      },
      beforeLeave(data) {
        document.querySelector(".page_nav_box").classList.add("active");
        document.querySelector(".inner_footer").classList.add("active");
        document.querySelector(".nav_header").classList.remove("fade_hidden");
        footer_visual();
        localStorage.setItem("hero_video_set", "1");
      },
    },
    {
      namespace: "map",
      once(data) {},
      beforeEnter({ next }) {
        // loadScript('greenway-map-data', window.location.origin + '/map-data', {defer: true})
        document.querySelector(".nav_header").classList.remove("fade_hidden");
        document.querySelector(".nav_header").classList.add("dark");
        document.querySelector(".main_logo").classList.add("logo_light");
        document.querySelector(".main_logo").classList.remove("logo_dark");
        document.querySelector(".page_nav_box").classList.remove("active");
        document.querySelector(".inner_footer").classList.remove("active");
      },
      afterEnter({ current, next, trigger }) {
        loadScript(
          "greenway-map",
          window.location.origin +
            "/app/plugins/GCUF-national-greenway-map/build/static/js/main.js?ver=0.4",
          { defer: true }
        );

        // document.querySelector(".course_video").style.height =
        //   document.querySelector(".course_video").offsetWidth / 1.778 + "px";

        // setTimeout(()=>{
        //   document.querySelector(".mapboxgl-marker").addEventListener('click', ()=>{
        //     console.log('hi');
        //   });
        // }, 2000);
        // $(".slide_row").slick({
        //   slidesToShow: 1,
        //   speed: 600,
        //   arrows: true,
        //   autoplay: true,
        //   autoplaySpeed: 4000,
        //   dots: false,
        //   fade: true,
        //   // useTransform: false,
        //   // useCSS: false,
        //   infinite: true,
        // });
      },
      enter() {},
      beforeLeave(data) {
        document.querySelector(".nav_header").classList.remove("dark");
        document.querySelector(".main_logo").classList.add("logo_dark");
        document.querySelector(".main_logo").classList.remove("logo_light");
        document.querySelector(".page_nav_box").classList.add("active");
        document.querySelector(".inner_footer").classList.add("active");
        footer_visual();

        removeScript("greenway-map");
      },
    },
    {
      namespace: "archive_course",
      once(data) {},
      beforeEnter({ next }) {
        document.querySelector(".nav_header").classList.remove("fade_hidden");
        document.querySelector(".page_nav_box").classList.add("active");
        document.querySelector(".inner_footer").classList.add("active");
        footer_visual();
      },
      afterEnter({ current, next, trigger }) {},
    },
    {
      namespace: "single_course",
      once(data) {},
      beforeEnter({ next }) {
        document.querySelector(".nav_header").classList.remove("fade_hidden");
        document.querySelector(".page_nav_box").classList.add("active");
        document.querySelector(".inner_footer").classList.add("active");
        footer_visual();

        var cf_selector = "div.wpcf7 > form";
        var cf_forms = $(next.container).find(cf_selector);
        if (cf_forms.length > 0) {
          // console.log("1");
          $(cf_selector).each(function() {
            var $form = $(this);
            wpcf7.initForm($form);
            if (wpcf7.cached) {
              wpcf7.refill($form);
            }
          });
        }
      },
      afterEnter({ current, next, trigger }) {
        if (document.querySelector(".video_player") != null) {
          var player = {
            playVideo: function(container, videoId) {
              if (typeof YT == "undefined" || typeof YT.Player == "undefined") {
                window.onYouTubePlayerAPIReady = function() {
                  player.loadPlayer(container, videoId);
                };
                // $.getScript("//www.youtube.com/player_api");
              } else {
                player.loadPlayer(container, videoId);
              }
            },
            loadPlayer: function(container, videoId) {
              window.MainPlayer = new YT.Player(container, {
                height: "100%",
                width: "100%",
                videoId: videoId,
                playerVars: {
                  // autoplay: 0,
                  // controls: 0,
                  // playsinline: 1,
                  // autohide: 1,
                  // showinfo: 0,
                  // modestbranding: 1,
                  // rel: 0,
                  // fs: 0,
                },
                events: {
                  onReady: onYouTubeIframeAPIReady,
                },
              });
              function onYouTubeIframeAPIReady(event) {
                player.playVideo(containerId, "QOjmvL3e7Lc");
              }
            },
          };

          var containerId = "player";
          var videoId = document.querySelectorAll(".video_item")[0].dataset
            .video_id;
          player.playVideo(containerId, videoId);

          document.querySelector(".video_player").style.height =
            document.querySelector(".video_player").offsetWidth / 1.778 + "px";

          var video_player = document.querySelector(".video_player iframe");
          var video_item = document.querySelectorAll(".video_item");
          video_item.forEach((ele) => {
            ele.addEventListener("click", function() {
              var videoId = ele.dataset.video_id;
              // player.loadPlayer("#player", videoId);

              if (!ele.classList.contains(".playing")) {
                ele.classList.add("playing");
                document.querySelector(".video_player iframe").src =
                  "https://www.youtube.com/embed/" +
                  videoId +
                  "?autoplay=1&showinfo=0&enablejsapi=1";
              } else {
                ele.classList.remove("playing");
              }
            });
          });
        }

        $(".grid_item_group .grid_item").click(function(e) {
          // $(".grid_item_group .grid_item").removeClass("in");
          $(this).toggleClass("in");
        });

        $(".form_submit input[type=submit]").on("mouseenter", function() {
          $(
            ".form_content input[type=text], .form_content textarea, .form_content input[type=hidden], .form_content select"
          ).each(function() {
            var id = $(this).attr("id");
            $(".form_data input[name=" + id + "]").val($(this).val());
          });
          var q2_arr = [];
          $(".q2_arr .grid_item.in").each(function() {
            var name = $(this).text();
            q2_arr.push(name);
          });
          $(".form_data input[name=q2_arr]").val(q2_arr);

          var q3_arr = [];
          $(".q3_arr .grid_item.in").each(function() {
            var name = $(this).text();
            q3_arr.push(name);
          });
          $(".form_data input[name=q3_arr]").val(q3_arr);
        });
      },
      enter() {},
      beforeLeave(data) {},
    },
    {
      namespace: "homework",
      beforeEnter({ next }) {
        document.querySelector(".nav_header").classList.remove("fade_hidden");
        document.querySelector(".page_nav_box").classList.add("active");
        document.querySelector(".inner_footer").classList.add("active");
        footer_visual();
        // console.log("0");
        var cf_selector = "div.wpcf7 > form";
        var cf_forms = $(next.container).find(cf_selector);
        if (cf_forms.length > 0) {
          // console.log("1");
          $(cf_selector).each(function() {
            var $form = $(this);
            wpcf7.initForm($form);
            if (wpcf7.cached) {
              wpcf7.refill($form);
            }
          });
        }
      },
      afterEnter({ current, next, trigger }) {
        $(".img_upload_input").each(function(index, value) {
          var $this = $(this);
          // var $this_id = $(this).attr("id");
          //  $box = $this.find(".img_upload_box");
          // var $file = $this;
          //  $remove = $this.find(".remove_img");
          //  $wrap = $this.find(".file_upload_content");

          $this.change(
            (function($this) {
              return function(file) {
                var $target = $this.attr("id");
                var $current = $("#" + $target);

                if (file.target.files[0].size > 10000000) {
                  alert("請選擇容量 10MB 以下檔案");
                } else {
                  if (file.target.files && file.target.files[0]) {
                    var reader = new FileReader();
                    // console.log($target);
                    $(".img_upload_box")
                      .find(".file_name")
                      .html(event.target.files[0].name);
                    // console.log($target);
                    reader.onload = function(e) {
                      // console.log(e.fileName);
                      //  $current
                      //    .find(".file_upload_image")
                      //    .css("background-image", "url(" + e.target.result + ")");
                      //  $current.find(".img_upload_box").addClass("in");
                      //  $current
                      //    .parent()
                      //    .find(".img_caption")
                      //    .focus();
                      //  $current.find(".file_upload_content").addClass("in");
                    };
                    reader.readAsDataURL(file.target.files[0]);

                    console.log($target);
                    $(".form_data input[name=" + $target + "]").prop(
                      "files",
                      $(".form_content #" + $target).prop("files")
                    );
                  } else {
                    // $this.find('.file_upload_image').removeClass('in');
                    // $this.find('.img_upload_box').removeClass('in');
                  }
                }
              };
            })($this)
          );
        });

        $(".grid_item_group .grid_item").click(function(e) {
          // $(".grid_item_group .grid_item").removeClass("in");
          $(this).toggleClass("in");
        });

        $(".form_submit input[type=submit]").on("mouseenter", function() {
          $(
            ".form_content input[type=text], .form_content textarea, .form_content input[type=hidden], .form_content select"
          ).each(function() {
            var id = $(this).attr("id");
            $(".form_data input[name=" + id + "]").val($(this).val());
          });
          var q2_arr = [];
          $(".q2_arr .grid_item.in").each(function() {
            var name = $(this).text();
            q2_arr.push(name);
          });
          $(".form_data input[name=q2_arr]").val(q2_arr);

          var q3_arr = [];
          $(".q3_arr .grid_item.in").each(function() {
            var name = $(this).text();
            q3_arr.push(name);
          });
          $(".form_data input[name=q3_arr]").val(q3_arr);
        });
      },
      enter() {},
      beforeLeave(data) {},
    },
    {
      namespace: "woocommerce_account",
      once(data) {},
      beforeEnter({ next }) {
        // document.querySelector(".page_nav_box").classList.add("active");
        document.querySelector(".nav_header").classList.remove("fade_hidden");
        document.querySelector(".inner_footer").classList.add("active");
        footer_visual();
      },
      afterEnter({ current, next, trigger }) {},
    },
    {
      namespace: "page",
      once(data) {},
      beforeEnter({ next }) {
        // document.querySelector(".page_nav_box").classList.add("active");
        document.querySelector(".nav_header").classList.remove("fade_hidden");
        document.querySelector(".inner_footer").classList.add("active");
        footer_visual();
      },
      afterEnter({ current, next, trigger }) {},
    },
    // {
    //   namespace: "invitation",
    //   beforeEnter({ next }) {},
    //   afterEnter({ current, next, trigger }) {},
    //   enter() {},
    // },
  ],
  transitions: [
    {
      name: "dummy-transition",

      // apply only when leaving `[data-barba-namespace="home"]`
      // from: 'home',

      // // apply only when transitioning to `[data-barba-namespace="products | contact"]`
      // to: {
      //   namespace: [
      //     'home',
      //     'about'
      //   ]
      // },

      // apply only if clicked link contains `.cta`
      // custom: ({ current, next, trigger }) => trigger.classList && trigger.classList.contains('cta'),

      // do leave and enter concurrently
      sync: true,

      // available hooks…
      beforeOnce() {},
      once() {},
      afterOnce() {},

      beforeLeave(data) {},
      leave({ current, next, trigger }) {
        current.container.classList.add("loading");
        next.container.classList.add("loading");

        const done = this.async();
        var next_container = next.container;

        setTimeout(function() {
          next.container.classList.remove("loading");
          done();
        }, 1000);
      },
      afterLeave({ current, next, trigger }) {
        window.scrollTo({
          top: 0,
          // behavior: "smooth",
        });
      },
      beforeEnter({ current, next, trigger }) {
        // console.log('global beforeEnter');
        const section = next.container.querySelectorAll(
          "section, .fadein_item"
        );
        section.forEach((element) => {
          if (element.getBoundingClientRect().y > -window.innerHeight) {
            element.classList.add("enter");
          } else {
            // element.classList.remove("enter");
          }
        });
      },
      enter(data) {
        loaded();
        scrolling();
        // ga('set', 'page', window.location.pathname);
        // ga('send', 'pageview');
        // console.log('global enter');
        // $('.container div section:first-child').addClass('in');
      },
      afterEnter({ current, next, trigger }) {
        // loaded();
        // scrolling();
        // console.log('global afterEnter');
        // console.log(next.container.querySelector('.canvas'));

        if (next.container.querySelector(".canvas") != null) {
          setTimeout(() => {
            init();
            animate();
          }, 1000);
        }
      },
    },
  ],
});

barba.hooks.after(() => {
  ga("set", "page", window.location.pathname);
  ga("send", "pageview");
  gtag("config", "UA-205128740-1", { page_path: window.location.pathname });
});
